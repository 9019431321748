


















































































































import BaseClass from '@/base';
import { Component } from 'vue-property-decorator';
import firebase from '@/plugins/firebase';

@Component
export default class ApproveJobAdsView extends BaseClass {
    posts: firebase.firestore.DocumentData[] = [];
    viewAd: firebase.firestore.DocumentData | null = null;
    viewAdDialog = false;

    async created() {
        this.$bind(
            'posts',
            this.firebase
                .firestore()
                .collection('posts')
                .where('status', '==', 'Awaiting Approval')
                .orderBy('timestamp', 'desc')
        );
    }

    async reject() {
        this.loading = true;
        try {
            this.firebase
                .firestore()
                .doc(`posts/${this.viewAd?.id}`)
                .update({
                    status: 'Rejected',
                });

            this.viewAd = null;
            this.viewAdDialog = false;
            this.snackMessage('Job ad has been rejected.', 'success');
        } catch (error) {
            this.snackMessage(error.message);
        }
        this.loading = false;
    }

    async approve() {
        this.loading = true;
        try {
            this.firebase
                .firestore()
                .doc(`posts/${this.viewAd?.id}`)
                .update({
                    status: 'Approved',
                });

            this.viewAd = null;
            this.viewAdDialog = false;
            this.snackMessage('Job ad has been approved.', 'success');
        } catch (error) {
            this.snackMessage(error.message);
        }
        this.loading = false;
    }

    async view(post: firebase.firestore.DocumentData) {
        this.viewAd = post;
        this.viewAdDialog = true;
    }
}
